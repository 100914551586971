import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
}

export default function CreditCardIcon({ width, height }: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6213 14.8869H1.37867C0.617333 14.8869 0 14.2696 0 13.5083V1.37867C0 0.617333 0.617333 0 1.37867 0H22.6213C23.3827 0 24 0.617333 24 1.37867V13.508C24 14.2696 23.3827 14.8869 22.6213 14.8869Z"
          fill="#9B9B9F"
        />
        <path
          d="M22.6175 0H22.2431V6.27493C22.2431 10.5472 18.7799 14.0104 14.5076 14.0104H0.09375C0.294817 14.5229 0.791083 14.8869 1.37482 14.8869H22.6175C23.3788 14.8869 23.9962 14.2696 23.9962 13.5083V1.37867C23.9962 0.617333 23.3788 0 22.6175 0Z"
          fill="#9B9B9F"
        />
        <path
          d="M5.05728 4.85041H2.11301C1.80021 4.85041 1.54688 4.59681 1.54688 4.28428V2.69895C1.54688 2.38615 1.80048 2.13281 2.11301 2.13281H5.05728C5.37007 2.13281 5.62341 2.38641 5.62341 2.69895V4.28428C5.62341 4.59681 5.37007 4.85041 5.05728 4.85041Z"
          fill="#EAA500"
        />
        <path
          d="M5.30719 7.74417H1.87599C1.71146 7.74417 1.57812 7.6111 1.57812 7.4463C1.57812 7.2815 1.71146 7.14844 1.87599 7.14844H5.30746C5.47199 7.14844 5.60533 7.2815 5.60533 7.4463C5.60533 7.6111 5.47173 7.74417 5.30719 7.74417Z"
          fill="#545C56"
        />
        <path
          d="M10.3072 7.74417H6.87599C6.71146 7.74417 6.57812 7.6111 6.57812 7.4463C6.57812 7.2815 6.71146 7.14844 6.87599 7.14844H10.3075C10.472 7.14844 10.6053 7.2815 10.6053 7.4463C10.6053 7.6111 10.4717 7.74417 10.3072 7.74417Z"
          fill="#545C56"
        />
        <path
          d="M15.3234 7.74417H11.8916C11.7271 7.74417 11.5938 7.6111 11.5938 7.4463C11.5938 7.2815 11.7268 7.14844 11.8916 7.14844H15.3234C15.4879 7.14844 15.6212 7.2815 15.6212 7.4463C15.6212 7.6111 15.4879 7.74417 15.3234 7.74417Z"
          fill="#545C56"
        />
        <path
          d="M20.339 7.74417H16.9072C16.7427 7.74417 16.6094 7.6111 16.6094 7.4463C16.6094 7.2815 16.7424 7.14844 16.9072 7.14844H20.339C20.5035 7.14844 20.6368 7.2815 20.6368 7.4463C20.6368 7.6111 20.5035 7.74417 20.339 7.74417Z"
          fill="#545C56"
        />
        <path
          d="M8.28506 12.8018H1.87599C1.71146 12.8018 1.57812 12.6687 1.57812 12.5039C1.57812 12.3394 1.71146 12.2061 1.87599 12.2061H8.28506C8.44959 12.2061 8.58292 12.3391 8.58292 12.5039C8.58292 12.6687 8.44959 12.8018 8.28506 12.8018Z"
          fill="#545C56"
        />
        <path
          d="M8.27251 11.0157H6.03224C5.86771 11.0157 5.73438 10.8826 5.73438 10.7178C5.73438 10.553 5.86771 10.4199 6.03224 10.4199H8.27251C8.43704 10.4199 8.57037 10.553 8.57037 10.7178C8.57037 10.8826 8.43704 11.0157 8.27251 11.0157Z"
          fill="#545C56"
        />
        <path
          d="M19.6463 10.2285C19.225 10.2285 18.8533 10.4242 18.6042 10.725C18.3549 10.4242 17.9831 10.2285 17.5621 10.2285C16.8117 10.2285 16.2031 10.837 16.2031 11.5874C16.2031 12.3378 16.8117 12.9464 17.5621 12.9464C17.9834 12.9464 18.3551 12.7506 18.6042 12.4498C18.8535 12.7506 19.2253 12.9464 19.6463 12.9464C20.3967 12.9464 21.0053 12.3378 21.0053 11.5874C21.0053 10.8368 20.3967 10.2285 19.6463 10.2285Z"
          fill="#545C56"
        />
        <path
          d="M18.8438 12.6803C19.0672 12.8462 19.3411 12.9467 19.6411 12.9467C20.3915 12.9467 21 12.3382 21 11.5878C21 11.304 20.9126 11.0411 20.764 10.8232C20.236 11.5475 19.5867 12.1771 18.8438 12.6803Z"
          fill="#494F4A"
        />
      </svg>
    </Box>
  );
}
