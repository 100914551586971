import { Box } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";

interface IProps {
  width: string;
  height: string;
  color?: string;
}

export default function StudentHeartIcon({
  width,
  height,
  color = Theme.mediumYellow,
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3764 23.1248L13.3753 23.1238C10.3402 20.3716 7.88176 18.1407 6.17312 16.0518C4.47309 13.9733 3.59375 12.1269 3.59375 10.1566C3.59375 6.95853 6.09622 4.45605 9.29434 4.45605C11.108 4.45605 12.8604 5.304 14.0011 6.64353L14.5721 7.31408L15.1431 6.64353C16.2838 5.304 18.0362 4.45605 19.8499 4.45605C23.048 4.45605 25.5505 6.95853 25.5505 10.1566C25.5505 12.127 24.6711 13.9734 22.9709 16.0534C21.2632 18.1427 18.8068 20.3749 15.7742 23.1307L15.7699 23.1347L15.7683 23.1361L14.574 24.2151L13.3764 23.1248Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M17.6454 10.0551C17.6454 11.9991 16.0708 13.5736 14.1269 13.5736C12.1829 13.5736 10.6083 11.9991 10.6083 10.0551L10.7051 9.22825L7.96947 7.85604L14.1269 4.77734L20.2842 7.85604V12.2542H19.4046V8.29585L17.5486 9.22825L17.6454 10.0551ZM14.1269 15.3329C18.0148 15.3329 21.1639 16.9074 21.1639 18.8514V20.6106H7.08984V18.8514C7.08984 16.9074 10.2389 15.3329 14.1269 15.3329Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}
