import { Stack, Text } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";

export default function DescriptionTitle(): JSX.Element {
  return (
    <Stack spacing={"0px"} maxWidth={"872px"} textAlign={"center"} zIndex={1}>
      <Text fontSize={["24px", "24px", "28px", "36px", "44px"]}>
        SEUS{" "}
        <Text as={"span"} fontWeight={"bold"}>
          PEDIDOS
        </Text>{" "}
        MAIS
      </Text>
      <Text
        fontSize={["24px", "28px", "36px", "48px", "56px"]}
        color={Theme.darkYellow}
        fontWeight={"bold"}
      >
        BARATOS{" "}
        <Text as={"span"} fontWeight={"normal"} color={Theme.darkestNeutral}>
          E
        </Text>{" "}
        DESTACADOS
      </Text>
    </Stack>
  );
}
