export const Theme = {
  // Purple
  darkestPurple_1: "#180A29",
  darkestPurple_2: "#2A004F",
  darkestPurple_3: "#361E61",
  darkPurple: "#370080",
  mediumPurple_1: "#5F00DB",
  mediumPurple_2: "#8531F3",
  lightPurple: "#A966FF",
  lightestPurple: "#F1E5FF",

  // Aquamarine (brand green)
  darkestAquamarine: "#003329",
  darkAquamarine: "#03BB96",
  mediumAquamarine: "#00DBAF",
  lightAquamarine: "#66FFE0",
  lightestAquamarine: "#E5FFFA",

  // Green
  darkGreen: "#138F4C",
  mediumGreen: "#14AB59",
  lightGreen: "#9BFFA5",
  lightestGreen: "#BCF0D4",

  // Red
  darkestRed: "#A60000",
  darkRed: "#CC0A0A",
  mediumRed: "#F23030",
  lightRed: "#FA9191",
  lightestRed: "#FFE5E5",

  // Yellow
  darkYellow: "#F3AD23",
  mediumYellow: "#FDD229",
  lightYellow: "#FFE353",
  lightestYellow: "#F2EDBB",

  // Black and White
  black: "#000000",
  darkestNeutral: "#2D3338",
  darkNeutral: "#424D57",
  mediumNeutral_1: "#6E8091",
  mediumNeutral_2: "#85858A",
  lightNeutral_1: "#919EAB",
  lightNeutral_2: "#C5CCD3",
  lightNeutral_3: "#CBCBCB",
  lightNeutral_4: "#EBEBEB",
  lightNeutral_5: "#ABABAB",
  lightestNeutral_1: "#F2F2F2",
  lightestNeutral_2: "#F6F6F6",
  lightestNeutral_3: "#FAFAFA",
  white: "#FFFFFF",

  // Others
  maxWidthDashboard: 1440,
  brown: "#121102",
};
