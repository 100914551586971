import { Link, Stack, Text } from "@chakra-ui/react";

const ASSISTANCE_PHONE = "+5527992452465";
export const SUPPORT_LINK = `https://api.whatsapp.com/send?phone=${ASSISTANCE_PHONE}&text=Ola%2C%20gostaria%20de%20ajuda`;
export const LOGIN_LINK = "https://www.meuguru.com/login";
export const MAIN_PAGE_LINK = "https://www.meuguru.com/";

export interface IFAQItem {
  question: string;
  answer: string | JSX.Element;
}

export const faqItems: IFAQItem[] = [
  {
    question: "Quais as vantagens do Plano Guru PRO?",
    answer: (
      <Stack spacing={"16px"}>
        <Text>
          Tornando-se PRO, você economizará e fará parte de um grupo seleto de
          alunos que possuem seus pedidos destacados com:
        </Text>
        <Stack spacing={"4px"}>
          <Text>
            a) Ganhe cashback de <b>10% em todos os pedidos pagos.</b>
          </Text>
          <Text>
            b) <b>5 boosts</b> por mês para deixar seus pedidos em evidência
            (você gastaria 49,50 se não fosse PRO).
          </Text>
          <Text>
            c) <b>8 gurus favoritos</b> por mês para tutores que já te conhecem
            saberem que você precisa de uma nova ajuda (você gastaria 39,20 se
            não fosse PRO).
          </Text>
          <Text>
            d) <b>Apareça como PRO</b> para todos os tutores e destaque seus
            pedidos.
          </Text>
        </Stack>
      </Stack>
    ),
  },
  {
    question: "Consigo usar a Meu Guru sem ter o plano?",
    answer: (
      <Stack spacing={"16px"}>
        <Text>
          <b>Consegue sim (como sempre foi!)</b>
        </Text>
        <Text>
          O Guru PRO é um plano de assinatura que permite que você gaste menos
          (economize em seus pedidos, destaque-os e tenha benefícios especiais).
          Em resumo,{" "}
          <b>
            O PRO é uma recorrência que favorece aos usuários que amam a Meu
            Guru
          </b>
          .
        </Text>
      </Stack>
    ),
  },
  {
    question: "Quais as formas de pagamento?",
    answer: (
      <Stack spacing={"16px"}>
        <Text>Você pode pagar o Guru PRO das seguintes formas:</Text>
        <Stack spacing={"8px"}>
          <Text>
            <b>1) Cartão de crédito</b> (Visa, MasterCard, Diners, American
            Express e ELO):
          </Text>
          <Text>
            Para o PRO Mensal, o pagamento é apenas à vista. Já para o PRO
            Trimestral e Anual, o pagamento pode ser à vista ou em até 3x sem
            juros, no caso do Trimestral, e em até 12x sem juros, no Premium
            Anual. No caso do pagamento em cartão, o Plano é renovado
            automaticamente após o período de assinatura, até que haja pedido de
            cancelamento.
          </Text>
          <Text>
            <b>2) Boleto bancário:</b> Disponível para o PRO Trimestral e Anual.
            Pagamento à vista, em parcela única.
          </Text>
          <Text>
            <b>3) Pix:</b> Disponível para o PRO Trimestral e Anual. Pagamento à
            vista, em parcela única.
          </Text>
        </Stack>
      </Stack>
    ),
  },
  {
    question: "Em quanto tempo meu Guru PRO é ativado após o pagamento?",
    answer: (
      <Text>
        Para pagamentos com cartão de crédito, o acesso é liberado bem rápido,
        logo após a autorização de pagamento. Para boleto bancário, pode levar
        até 3 dias úteis após a realização do pagamento. Para o Pix, o acesso é
        instantâneo.
      </Text>
    ),
  },
  {
    question: "Em que caso posso cancelar e receber reembolso?",
    answer: (
      <Stack spacing={"16px"}>
        <Text>
          Caso não curta seu plano, seu reembolso é rápido e sem burocracia. É
          só clicar{" "}
          <Link fontWeight={"bold"} href={SUPPORT_LINK} target="_blank">
            nesse link
          </Link>{" "}
          e fazer a sua solicitação, beleza? Nosso atendimento no suporte é de
          09 as 18h todos os dias.
        </Text>
        <Text>
          Você tem o prazo de até 7 dia para cancelar o Guru PRO a partir da
          data de assinatura (seja no Plano Mensal, Trimestral ou Anual).
        </Text>
        <Text>
          No caso do Guru PRO Mensal, se você cancelar mais de 7 dias após a
          data de assinatura, a renovação automática da assinatura será
          cancelada, mas não haverá reembolso e deverá ser paga as faturas em
          aberto. Você continuará com o acesso PRO até o final do último período
          de 30 dias contratado.
        </Text>
        <Text>
          O reembolso é liberado em situações onde o benefício do cashback não
          tenha sido aplicado em nenhum pedido.
        </Text>
        <Text>
          Para mais detalhes desse cálculo, é só falar com a gente{" "}
          <Link fontWeight={"bold"} href={SUPPORT_LINK} target="_blank">
            neste link
          </Link>
          .
        </Text>
      </Stack>
    ),
  },
];
