import alicia from "../../assets/png/teachers/alicia.png";
import gabriel from "../../assets/png/teachers/gabriel.png";
import joao from "../../assets/png/teachers/joao.png";
import leticia from "../../assets/png/teachers/leticia.png";
import marcos from "../../assets/png/teachers/marcos.png";
import rodrigo from "../../assets/png/teachers/rodrigo.png";
import victor from "../../assets/png/teachers/victor.png";
import isabelle from "../../assets/png/teachers/isabelle.png";

export interface ITeacherCard {
  picPath: string;
  name: string;
  course: string;
  universityInitial: string;
  stars: string;
  ratings: number;
}

export const teacherCards: ITeacherCard[] = [
  {
    // 0
    picPath: joao,
    name: "João",
    course: "Eng. Civil",
    universityInitial: "UFLA",
    stars: "4,8",
    ratings: 39,
  },
  {
    // 1
    picPath: leticia,
    name: "Letícia",
    course: "Direito",
    universityInitial: "USP",
    stars: "5,0",
    ratings: 8,
  },
  {
    // 2
    picPath: marcos,
    name: "Marcos",
    course: "Matemática",
    universityInitial: "UFMG",
    stars: "4,9",
    ratings: 48,
  },
  {
    // 3
    picPath: gabriel,
    name: "Gabriel",
    course: "Eng. Mecânica",
    universityInitial: "ITA",
    stars: "4,7",
    ratings: 21,
  },
  {
    // 4
    picPath: rodrigo,
    name: "Rodrigo",
    course: "Administração",
    universityInitial: "UFMS",
    stars: "5,0",
    ratings: 52,
  },
  {
    // 5
    picPath: victor,
    name: "Victor",
    course: "Eng. Mecânica",
    universityInitial: "UFSC",
    stars: "4,9",
    ratings: 193,
  },
  {
    // 6
    picPath: alicia,
    name: "Alicia",
    course: "Bioquímica",
    universityInitial: "UFV",
    stars: "5,0",
    ratings: 85,
  },
  {
    // 7
    picPath: isabelle,
    name: "Isabelle",
    course: "Química",
    universityInitial: "USP",
    stars: "4,1",
    ratings: 18,
  },
];
