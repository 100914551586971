import { Box, Icon, Image, Stack } from "@chakra-ui/react";
import { BiRocket } from "react-icons/bi";
import GraduationCapIcon from "../../../../shared/vectors/GraduationCapIcon";
import StudentHeartIcon from "../../../../shared/vectors/StudentHeartIcon";
import { Theme } from "../../../../styles/Theme";
import faqImg from "../../../../assets/png/faq-img.png";

export default function FAQImageBox(): JSX.Element {
  return (
    <Box position={"relative"}>
      <Box
        width={"466px"}
        height={"466px"}
        opacity={"0.25"}
        borderRadius={"50%"}
        border={`1.5px solid ${Theme.lightestNeutral_1}`}
      />
      <Box
        width={"394px"}
        height={"394px"}
        opacity={"0.45"}
        borderRadius={"50%"}
        border={`1.5px solid ${Theme.lightestNeutral_1}`}
        position={"absolute"}
        top={"52px"}
        left={"5px"}
      />
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        width={"fit-content"}
        height={"fit-content"}
        padding={"6px"}
        borderRadius={"5px"}
        backgroundColor={Theme.darkNeutral}
        color={Theme.mediumYellow}
        position={"absolute"}
        bottom={"144px"}
        left={"-9px"}
      >
        <Icon as={BiRocket} boxSize={"20px"} />
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"full"}
        width={"324px"}
        height={"324px"}
        position={"absolute"}
        top={"95px"}
        left={"24px"}
        overflow={"hidden"}
      >
        <Image src={faqImg} objectFit={"cover"} />
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        width={"fit-content"}
        height={"fit-content"}
        padding={"6px"}
        borderRadius={"5px"}
        backgroundColor={Theme.darkNeutral}
        color={Theme.mediumYellow}
        position={"absolute"}
        top={"156px"}
        right={"67px"}
      >
        <GraduationCapIcon width="32px" height="32px" />
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        width={"fit-content"}
        height={"fit-content"}
        padding={"6px"}
        borderRadius={"5px"}
        backgroundColor={Theme.darkNeutral}
        color={Theme.mediumYellow}
        position={"absolute"}
        top={"44px"}
        right={"186px"}
      >
        <StudentHeartIcon width="28px" height="28px" />
      </Stack>
    </Box>
  );
}
