import { AspectRatio, Box, Stack } from "@chakra-ui/react";
import BackgroundWave1 from "../../shared/vectors/BackgroundWave1";
import { Theme } from "../../styles/Theme";
import TeachersBox from "./components/boxes/TeachersBox";
import TeachersTitle from "./components/text/TeachersTitle";

export default function TeachersContainer(): JSX.Element {
  return (
    <Stack
      spacing={"0px"}
      alignItems={"center"}
      minHeight={"700px"}
      paddingTop={"74px"}
      paddingBottom={"136px"}
      position={"relative"}
      backgroundColor={Theme.white}
    >
      <Box
        display={["none", "none", "none", "block"]}
        width={"100vw"}
        minWidth={"500px"}
        position={"absolute"}
        top={"0px"}
        transform={"translateY(-50%)"}
      >
        <AspectRatio ratio={1440 / 355}>
          <BackgroundWave1 width="100%" height="100%" />
        </AspectRatio>
      </Box>

      <Stack
        direction={["column", "column", "column", "row"]}
        spacing={["52px", "52px", "52px", "36px"]}
        justifyContent={"center"}
        alignItems={["center", "center", "center", "flex-start"]}
        padding={["0px", "0px", "0px", "20px", "40px"]}
        width={"100vw"}
        maxWidth="1440px"
        position={["relative", "relative", "relative", "absolute"]}
      >
        <TeachersTitle />
        <TeachersBox transform={["", "", "", "translateY(-23%)"]} />
      </Stack>
    </Stack>
  );
}
