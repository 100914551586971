import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
  color?: string;
}

export default function BackgroundWave2({
  width,
  height,
  color = "none",
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1440 788"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1248.96 2.32496C1550.02 20.8297 1717.58 236.07 1700.86 377.79L1610.07 787L-78.1954 787L-78.1954 445.275C-215.55 367.36 -457.059 -10.5733 66.3694 93.738C724.294 224.852 779.861 -26.5085 1248.96 2.32496Z"
          fill="url(#paint0_linear_5984_24854)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5984_24854"
            x1="772.913"
            y1="470.605"
            x2="766.905"
            y2="-93.4067"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D3338" />
            <stop offset="1" stopColor="#420099" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
