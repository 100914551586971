import {
  Button,
  Divider,
  Link,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import BarCodeIcon from "../../../../shared/vectors/BarCodeIcon";
import CreditCardIcon from "../../../../shared/vectors/CreditCardIcon";
import PixIcon from "../../../../shared/vectors/PixIcon";
import { Theme } from "../../../../styles/Theme";

export default function QuarterlyCard({ ...props }: StackProps): JSX.Element {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      minWidth={["320px", "320px", "320px", "340px"]}
      minHeight={"432px"}
      paddingTop={"24px"}
      paddingBottom={"20px"}
      borderRadius={"10px"}
      boxShadow={"0px 2px 43px rgba(0, 0, 0, 0.1)"}
      textAlign={"center"}
      backgroundColor={Theme.white}
      zIndex={"0"}
      {...props}
    >
      <Stack spacing={"24px"}>
        <Stack spacing={"0px"} alignItems={"center"}>
          <Text
            fontSize={"12px"}
            fontWeight={"medium"}
            color={Theme.mediumNeutral_1}
          >
            Plano
          </Text>
          <Text fontSize={"18px"} fontWeight={"medium"}>
            Guru Pro
          </Text>
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={Theme.mediumPurple_1}
          >
            Trimestral
          </Text>
          <Divider width={"220px"} opacity={"1"} />
        </Stack>
        <Stack spacing={"24px"} alignItems={"center"}>
          <Stack spacing={"-8px"} width={"max-content"}>
            <Text
              alignSelf={"flex-start"}
              fontSize={"14px"}
              color={Theme.mediumNeutral_1}
            >
              3x de
            </Text>
            <Stack
              direction={"row"}
              spacing={"0px"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              fontWeight={"medium"}
            >
              <Text fontSize={"48px"}>R$ 39</Text>
              <Text fontSize={"32px"} paddingBottom={"6px"}>
                ,90
              </Text>
              <Text
                fontSize={"18px"}
                paddingLeft={"8px"}
                paddingBottom={"12px"}
              >
                /mês
              </Text>
            </Stack>
            <Text
              alignSelf={"flex-end"}
              fontSize={"14px"}
              fontWeight={"light"}
              color={Theme.mediumNeutral_1}
            >
              = 119,70/três meses
            </Text>
          </Stack>
          <Text fontSize={"12px"} color={Theme.mediumNeutral_1}>
            economize <b>R$ 120,00 por ano (20% OFF)</b>
          </Text>
        </Stack>
      </Stack>
      <Stack spacing={"20px"} alignItems={"center"}>
        <Link href="https://www.meuguru.com/portal/aluno/pagamento/assinatura">
          <Button
            minWidth={"220px"}
            rounded={"full"}
            backgroundColor={Theme.mediumPurple_1}
            _hover={{
              backgroundColor: Theme.lightPurple,
            }}
            color={Theme.white}
          >
            Assinar
          </Button>
        </Link>
        <Stack direction={"row"} spacing={"16px"} alignItems={"center"}>
          <Text
            fontSize={"10px"}
            fontWeight={"medium"}
            color={Theme.mediumNeutral_1}
          >
            pague com
          </Text>
          <Stack direction={"row"} alignItems={"center"}>
            <PixIcon width={"16px"} height={"16px"} />
            <CreditCardIcon width={"24px"} height={"16px"} />
            <BarCodeIcon width={"24px"} height={"16px"} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
