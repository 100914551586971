import {
  Box,
  Collapse,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";
import { IFAQItem } from "../../types";
import FAQButton from "../buttons/FAQButton";

interface IProps {
  item: IFAQItem;
}

export default function FAQItem({ item }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <Box display={["none", "none", "none", "block"]}>
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          placement="right"
          gutter={24}
        >
          <PopoverTrigger>
            <Box>
              <FAQButton
                label={item.question}
                isOpen={isOpen}
                onClick={onOpen}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            maxWidth={"300px"}
            borderRadius={"16px"}
            backgroundColor={Theme.lightestNeutral_1}
            fontSize={"12px"}
            color={Theme.darkestNeutral}
          >
            <PopoverArrow
              borderRadius={"0px 0px 0px 2px"}
              backgroundColor={Theme.lightestNeutral_1}
              boxShadow={"none !important"}
              transform={"scale(3,1.8) rotate(45deg) !important"}
            />
            <PopoverBody padding={"16px"}>{item.answer}</PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>

      <Box display={["block", "block", "block", "none"]}>
        <FAQButton label={item.question} isOpen={isOpen} onClick={onToggle} />
        <Collapse in={isOpen} animateOpacity>
          <Box
            padding={"16px 8px"}
            backgroundColor={Theme.lightestNeutral_1}
            fontSize={"14px"}
            color={Theme.darkestNeutral}
            boxShadow={"inner"}
          >
            {item.answer}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
