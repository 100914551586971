import { Box, Link, Stack, StackDivider, Text } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";
import { faqItems, SUPPORT_LINK } from "../../types";
import FAQItem from "../other/FAQItem";

export default function FAQBox(): JSX.Element {
  return (
    <Stack
      spacing={"0px"}
      divider={<StackDivider borderColor={Theme.lightestNeutral_1} />}
      fontFamily={"Inter, sans-serif"}
    >
      {faqItems.map((item, index) => (
        <Box key={`faqItem-${index}`} fontSize={["16px", "18px", "20px"]}>
          <FAQItem item={item} />
        </Box>
      ))}
      <Box padding={"16px 8px"}>
        <Text>
          Sua pergunta ainda não foi respondida?{" "}
          <Link
            fontWeight={"bold"}
            color={Theme.mediumYellow}
            href={SUPPORT_LINK}
            target="_blank"
          >
            Clique aqui
          </Link>{" "}
          e fale com a gente.
        </Text>
      </Box>
    </Stack>
  );
}
