import { Flex, Text, Link as A } from "@chakra-ui/react";
import { FaInstagram, FaLinkedinIn, FaTiktok, FaYoutube } from "react-icons/fa";
import LogoFooter from "../../assets/png/logoFooter";

const Footer = () => {
  return (
    <Flex bg="#F4F4F4" width="100%" minHeight="280px">
      <Flex
        position="initial"
        width="100%"
        maxWidth="1290px"
        mx="auto"
        px="25px"
      >
        <Flex align="initial" direction="column" width="100%">
          {/* Logo e Redes Sociais */}
          <Flex
            width="100%"
            height="53px"
            marginTop="56px"
            justify="space-between"
            direction="row"
          >
            <LogoFooter />
            <Flex
              color="#626262"
              fontSize="22px"
              align="center"
              display={["none", "none", "none", "none", "flex"]}
            >
              <A
                marginRight="24px"
                href="https://www.instagram.com/meugurubr/"
                target="_blank"
                color="#9CA3AF"
              >
                <FaInstagram />
              </A>
              <A
                marginRight="24px"
                href="https://www.tiktok.com/@meuguru_br"
                target="_blank"
                color="#9CA3AF"
              >
                <FaTiktok />
              </A>
              <A
                marginRight="24px"
                href="https://www.youtube.com/@MeuGuru"
                target="_blank"
                color="#9CA3AF"
              >
                <FaYoutube />
              </A>
              <A
                href="https://www.linkedin.com/company/meugurubr"
                target="_blank"
                color="#9CA3AF"
              >
                <FaLinkedinIn />
              </A>
            </Flex>
          </Flex>

          {/* Conta e Links  */}
          <Flex
            paddingTop="32px"
            flex="1"
            direction={["column", "column", "column", "column", "row"]}
            marginBottom="64px"
          >
            <Flex justify="space-between">
              <Flex
                direction="column"
                width={["50%", "50%", "50%", "50%", "299.5px"]}
                marginRight={["8px", "8px", "8px", "8px", "24px"]}
              >
                <Text fontSize="18px" fontWeight="500" marginBottom="24px">
                  Acesso
                </Text>

                <Flex fontSize="14px" lineHeight="20px">
                  <Flex direction="column">
                    <A marginBottom="16px" href="https://www.meuguru.com/registro">
                      Criar conta
                    </A>
                    <A marginBottom="16px" href="https://www.meuguru.com/login">
                      Entrar
                    </A>
                    <A
                      marginBottom="16px"
                      href={`https://sejatutor.meuguru.com/`}
                    >
                      Seja um tutor
                    </A>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                direction="column"
                width={["50%", "50%", "50%", "50%", "299.5px"]}
                marginRight={["8px", "8px", "8px", "8px", "24px"]}
              >
                <Text fontSize="18px" fontWeight="500" marginBottom="24px">
                  A empresa
                </Text>

                <Flex fontSize="14px" lineHeight="20px">
                  <Flex direction="column">
                    <A
                      marginBottom="16px"
                      href="https://linktr.ee/mgnamidia"
                      isExternal
                    >
                      Na mídia
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://comunidade.meuguru.com/carreiras"
                      isExternal
                    >
                      Carreiras
                    </A>
                    <A
                      marginBottom="16px"
                      href={`https://comunidade.meuguru.com/elearning/`}
                    >
                      Dados do e-learning
                    </A>
                    <A
                      marginBottom="16px"
                      href={`https://api.whatsapp.com/send/?phone=5527992452465&text&type=phone_number&app_absent=0`}
                    >
                      Contato
                    </A>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex justify="space-between">
              <Flex
                direction="column"
                marginTop={["48px", "48px", "48px", "48px", "0"]}
                width={["50%", "50%", "50%", "50%", "299.5px"]}
                marginRight={["8px", "8px", "8px", "8px", "24px"]}
              >
                <Text fontSize="18px" fontWeight="500" marginBottom="24px">
                  Plataforma
                </Text>

                <Flex fontSize="14px" lineHeight="20px">
                  <Flex direction="column">
                    <A marginBottom="16px" href="#comofunciona">
                      Como funciona
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://pro.meuguru.com/"
                      isExternal
                    >
                      Guru PRO
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://gururesponde.meuguru.com/"
                      isExternal
                    >
                      Guru Responde
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://blog.meuguru.com/"
                      isExternal
                    >
                      Blog
                    </A>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                direction="column"
                marginTop={["48px", "48px", "48px", "48px", "0"]}
                width={["50%", "50%", "50%", "50%", "299.5px"]}
                marginRight={["8px", "8px", "8px", "8px", "24px"]}
              >
                <Text fontSize="18px" fontWeight="500" marginBottom="24px">
                  Legal
                </Text>

                <Flex fontSize="14px" lineHeight="20px">
                  <Flex direction="column">
                    <A
                      marginBottom="16px"
                      href="https://meuguru-public-docs.s3.sa-east-1.amazonaws.com/Termos%20de%20uso.html"
                      isExternal
                    >
                      Termos de uso
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://meuguru-public-docs.s3.sa-east-1.amazonaws.com/Termos%20de%20uso.html"
                      isExternal
                    >
                      Termos de serviço
                    </A>
                    <A
                      marginBottom="16px"
                      href="https://meuguru-public-docs.s3.sa-east-1.amazonaws.com/Pol%C3%ADtica+de+privacidade.html"
                      isExternal
                    >
                      Política de privacidade
                    </A>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            color="#626262"
            fontSize="18px"
            align="center"
            display={["flex", "flex", "flex", "flex", "none"]}
            marginBottom="32px"
          >
            <A
              marginRight="24px"
              href="https://www.instagram.com/meugurubr/"
              target="_blank"
              color="#9CA3AF"
            >
              <FaInstagram />
            </A>
            <A
              marginRight="24px"
              href="https://www.tiktok.com/@meuguru_br"
              target="_blank"
              color="#9CA3AF"
            >
              <FaTiktok />
            </A>
            <A
              marginRight="24px"
              href="https://www.youtube.com/@MeuGuru"
              target="_blank"
              color="#9CA3AF"
            >
              <FaYoutube />
            </A>
            <A
              href="https://www.linkedin.com/company/meugurubr"
              target="_blank"
              color="#9CA3AF"
            >
              <FaLinkedinIn />
            </A>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
