import { Button, ButtonProps, Link } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";

export default function WannaBeProButton({
  ...props
}: ButtonProps): JSX.Element {
  return (
    <Link href="#ofertas">
      <Button
        rounded={"full"}
        width={"100%"}
        maxWidth={"287px"}
        padding={["16px 8px", "24px 12px", "28px 16px"]}
        fontSize={["16px", "20px", "24px"]}
        fontWeight={"bold"}
        color={Theme.darkestNeutral}
        backgroundColor={Theme.mediumYellow}
        border={`1px solid ${Theme.darkestNeutral}`}
        _hover={{ backgroundColor: Theme.lightYellow }}
        {...props}
      >
        Quero ser Guru PRO
      </Button>
    </Link>
  );
}
