import { Box } from '@chakra-ui/react';
import { Theme } from '../../styles/Theme';

interface IProps {
  width: string;
  height: string;
  color_1?: string;
  color_2?: string;
}

export default function Logo({
  width,
  height,
  color_1 = Theme.white,
  color_2 = Theme.mediumAquamarine,
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 817 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64.9003 46.39C67.0303 46.39 69.0703 46.88 70.9503 47.78C80.6303 38.29 89.7803 27.37 95.0603 16.63C91.9103 12.85 85.7203 8.52998 83.5003 6.78998C67.3003 26.4 35.8403 56.06 5.78027 80.1C7.54027 86.42 10.6903 90.98 16.2103 94.59C16.2103 94.59 27.8503 91.37 35.8203 86.16C39.8003 83.55 44.4803 80.2 49.0803 75.7C48.2803 73.23 47.8303 70.54 47.8303 67.71C47.8303 55.95 55.4903 46.38 64.9003 46.38V46.39Z"
          fill={color_1}
        />
        <path
          d="M78.2599 1.84C75.5899 0.56 72.7099 0 69.6899 0C59.7099 0 48.3899 6.16 39.2199 12.67C28.8699 20 4.63993 37.19 0.569934 54.55C-0.680066 59.87 0.109934 66 2.85993 72.74C30.1999 49.13 67.0799 15.76 78.2599 1.84Z"
          fill={color_1}
        />
        <path
          d="M128.85 54.55C126.61 44.98 118.22 35.5 109.45 27.63C106.52 25 101.09 21.54 101.09 21.54C101.09 21.54 97.9002 27.21 94.8402 31.51C90.0302 38.25 88.7402 40.11 88.7402 40.11C88.7402 40.11 95.0302 45.1 96.2402 46.2C106.93 55.89 118.63 65.91 126.56 72.76C129.31 66.01 130.1 59.89 128.85 54.57V54.55Z"
          fill={color_1}
        />
        <path
          d="M77.1504 53.17C80.2804 56.81 81.9704 62.27 81.9704 67.71C81.9704 70.64 81.4904 73.44 80.6304 75.99C85.1304 80.35 89.7004 83.61 93.5904 86.16C101.56 91.37 113.2 94.59 113.2 94.59C118.72 90.98 121.87 86.42 123.63 80.1C115.89 73.91 106.74 65.9 98.0704 58.3C93.5104 54.31 83.8504 45.91 83.8504 45.91C83.8504 45.91 83.2004 46.89 81.3804 48.81C80.1704 50.09 78.7504 51.52 77.1504 53.16V53.17Z"
          fill={color_1}
        />
        <path
          d="M72.8303 59.95C71.0803 56.29 68.1503 53.83 64.9003 53.83C64.7703 53.83 64.6503 53.83 64.5303 53.84C59.4803 54.13 55.2803 60.36 55.2803 67.71C55.2803 68.01 55.2803 68.3 55.3003 68.6C55.6303 75.73 59.8903 81.6 64.9103 81.6C69.9303 81.6 73.9703 76.04 74.4803 69.16C74.5203 68.69 74.5303 68.21 74.5303 67.72C74.5303 64.87 73.9003 62.19 72.8303 59.96V59.95Z"
          fill={color_1}
        />
        <path
          d="M186.54 9.08003H202.52L203.2 20.81C205.58 16.28 209.04 12.76 213.57 10.27C218.1 7.78003 223.32 6.53003 229.21 6.53003C241.45 6.53003 249.61 11.74 253.69 22.17C256.3 17.18 260.01 13.33 264.83 10.61C269.65 7.89003 275.28 6.53003 281.75 6.53003C291.16 6.53003 298.47 9.45003 303.68 15.28C308.89 21.12 311.5 29.36 311.5 40.01V88.12H294.33V42.05C294.33 35.36 292.94 30.29 290.16 26.84C287.38 23.38 283.39 21.66 278.18 21.66C271.95 21.66 266.99 23.98 263.3 28.63C259.62 33.28 257.78 39.51 257.78 47.33V88.13H240.61V42.06C240.61 28.46 235.11 21.66 224.12 21.66C217.89 21.66 212.93 23.98 209.24 28.63C205.56 33.28 203.71 39.51 203.71 47.33V88.13H186.54V9.08003Z"
          fill={color_1}
        />
        <path
          d="M319.48 48.69C319.48 40.53 321.18 33.28 324.58 26.93C327.98 20.58 332.65 15.6 338.61 11.97C344.56 8.34003 351.39 6.53003 359.09 6.53003C366.79 6.53003 373.74 8.37003 379.57 12.05C385.41 15.73 389.97 20.89 393.25 27.52C396.54 34.15 398.18 41.83 398.18 50.56V53.79H336.64C337.32 60.7 339.96 66.14 344.55 70.11C349.14 74.08 355.23 76.06 362.83 76.06C372.01 76.06 380.23 72.89 387.48 66.54L395.98 77.25C391.9 81.44 386.86 84.7 380.85 87.02C374.84 89.34 368.5 90.51 361.81 90.51C353.42 90.51 346.06 88.75 339.71 85.24C333.36 81.73 328.4 76.82 324.83 70.53C321.26 64.24 319.48 56.96 319.48 48.68V48.69ZM374.47 26.76C370.67 23.13 365.71 21.32 359.59 21.32C353.47 21.32 348.03 23.16 343.95 26.84C339.87 30.52 337.43 35.48 336.64 41.72H381.18C380.5 35.37 378.26 30.39 374.47 26.76Z"
          fill={color_1}
        />
        <path
          d="M408.73 57.19V9.08002H425.9V54.81C425.9 61.5 427.35 66.63 430.23 70.2C433.12 73.77 437.23 75.55 442.56 75.55C449.13 75.55 454.37 73.09 458.28 68.16C462.19 63.23 464.14 56.68 464.14 48.52V9.08002H481.31V88.13H465.5L464.82 76.06C462.1 80.59 458.39 84.14 453.68 86.68C448.98 89.23 443.68 90.51 437.78 90.51C428.71 90.51 421.6 87.59 416.45 81.76C411.29 75.92 408.71 67.73 408.71 57.19H408.73Z"
          fill={color_1}
        />
        <path
          d="M571.08 87.28V77.59C565.64 85.3 557.31 89.15 546.09 89.15C538.95 89.15 532.69 87.51 527.3 84.22C521.92 80.93 517.72 76.34 514.72 70.45C511.72 64.56 510.21 57.7 510.21 49.88C510.21 41.04 512.14 33.39 515.99 26.93C519.84 20.47 525.26 15.46 532.23 11.89C539.2 8.32004 547.39 6.54004 556.79 6.54004C561.89 6.54004 567.22 7.02004 572.77 7.98004C578.32 8.94004 583.37 10.33 587.9 12.15V86.61C587.9 98.74 584.39 108.09 577.36 114.66C570.33 121.23 560.25 124.52 547.1 124.52C533.95 124.52 523.24 121.18 514.63 114.49L522.11 101.91C529.7 107.35 537.92 110.07 546.76 110.07C562.97 110.07 571.07 102.48 571.07 87.29L571.08 87.28ZM570.74 52.09V23.36C565.87 22.11 561.33 21.49 557.14 21.49C547.85 21.49 540.62 23.95 535.47 28.88C530.31 33.81 527.74 40.64 527.74 49.37C527.74 56.96 529.72 63.05 533.69 67.64C537.66 72.23 542.93 74.53 549.5 74.53C556.07 74.53 560.97 72.43 564.88 68.24C568.79 64.05 570.75 58.66 570.75 52.09H570.74Z"
          fill={color_1}
        />
        <path
          d="M599.47 57.19V9.08002H616.64V54.81C616.64 61.5 618.09 66.63 620.97 70.2C623.86 73.77 627.97 75.55 633.3 75.55C639.87 75.55 645.11 73.09 649.02 68.16C652.93 63.23 654.88 56.68 654.88 48.52V9.08002H672.05V88.13H656.24L655.56 76.06C652.84 80.59 649.13 84.14 644.42 86.68C639.72 89.23 634.42 90.51 628.52 90.51C619.45 90.51 612.34 87.59 607.19 81.76C602.03 75.92 599.45 67.73 599.45 57.19H599.47Z"
          fill={color_1}
        />
        <path
          d="M683.96 9.08003H699.94L700.79 22C703.51 17.47 707.05 13.95 711.41 11.46C715.77 8.97003 720.62 7.72003 725.94 7.72003C728.89 7.72003 731.83 8.17003 734.78 9.08003L731.55 24.72C729.17 23.93 726.68 23.53 724.07 23.53C717.5 23.53 712.03 25.68 707.67 29.99C703.31 34.3 701.12 39.96 701.12 46.99V88.13H683.95V9.08003H683.96Z"
          fill={color_1}
        />
        <path
          d="M743.79 57.19V9.08002H760.961V54.81C760.961 61.5 762.41 66.63 765.29 70.2C768.18 73.77 772.29 75.55 777.62 75.55C784.19 75.55 789.431 73.09 793.341 68.16C797.251 63.23 799.201 56.68 799.201 48.52V9.08002H816.37V88.13H800.56L799.88 76.06C797.16 80.59 793.45 84.14 788.74 86.68C784.04 89.23 778.741 90.51 772.841 90.51C763.771 90.51 756.66 87.59 751.51 81.76C746.35 75.92 743.771 67.73 743.771 57.19H743.79Z"
          fill={color_1}
        />
      </svg>
    </Box>
  );
}
