import { Stack } from "@chakra-ui/react";
import { Theme } from "../styles/Theme";

interface IProps {
  children: JSX.Element[] | JSX.Element;
}

export default function Container({ children }: IProps): JSX.Element {
  return (
    <Stack
      spacing="0px"
      minHeight="100vh"
      background={Theme.white}
      overflowX="hidden"
      color={Theme.darkestNeutral}
      position={"relative"}
    >
      {children}
    </Stack>
  );
}
