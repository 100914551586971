import { Icon, Stack, StackProps, Text, useBreakpoint } from "@chakra-ui/react";
import { BiCheckShield } from "react-icons/bi";
import MountlyCard from "../cards/MountlyCard";
import QuarterlyCard from "../cards/QuarterlyCard";
import YearlyCard from "../cards/YearlyCard";

export default function OffersListBox({ ...props }: StackProps): JSX.Element {
  const breakPointValue = useBreakpoint();

  return (
    <Stack
      id="ofertas"
      spacing={"24px"}
      alignItems={"center"}
      paddingX={"16px"}
      {...props}
    >
      <Stack
        direction={["column", "column", "column", "row"]}
        spacing={["32px", "32px", "32px", "-28px"]}
        alignItems={"flex-end"}
      >
        {["base", "sm", "md"].includes(breakPointValue) && <YearlyCard />}
        <QuarterlyCard
          position={"relative"}
          bottom={["0px", "0px", "0px", "20px"]}
        />
        {!["base", "sm", "md"].includes(breakPointValue) && <YearlyCard />}
        <MountlyCard
          position={"relative"}
          bottom={["0px", "0px", "0px", "20px"]}
        />
      </Stack>

      <Stack
        direction={"row"}
        spacing={"16px"}
        alignItems={"center"}
        maxWidth={"280px"}
        fontSize={"14px"}
      >
        <Icon as={BiCheckShield} width="36px" height="36px" />
        <Stack spacing={"0px"}>
          <Text>Você feliz ou reembolsado!</Text>
          <Text>Garantia de 7 dias para conferir!</Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
