import { Stack, Text, useBreakpoint } from "@chakra-ui/react";
import WannaBeProButton from "../../shared/buttons/WannaBeProButton";
import { Theme } from "../../styles/Theme";
import FAQBox from "./components/boxes/FAQBox";
import FAQImageBox from "./components/boxes/FAQImageBox";

export default function FAQContainer(): JSX.Element {
  const breakPointValue = useBreakpoint();

  return (
    <Stack
      alignItems={"center"}
      width={"100vw"}
      paddingTop={["200px", "200px", "200px", "380px"]}
      paddingBottom={["148px", "148px", "148px", "200px"]}
      color={Theme.white}
      backgroundColor={Theme.darkestNeutral}
    >
      <Stack
        direction={"row"}
        spacing={"32px"}
        justifyContent={["center", "center", "center", "space-between"]}
        alignItems={"center"}
        width={"100%"}
        maxWidth={"1100px"}
        paddingX={"16px"}
      >
        <Stack spacing={"36px"} maxWidth={"464px"}>
          <Stack spacing={"0px"}>
            <Text
              fontSize={["24px", "34px", "46px"]}
              color={Theme.lightestNeutral_1}
            >
              Tire suas
            </Text>
            <Text
              fontSize={["56px", "64px", "72px"]}
              fontWeight={"semibold"}
              color={Theme.lightYellow}
            >
              dúvidas
            </Text>
          </Stack>
          <FAQBox />
          <WannaBeProButton
            alignSelf={["center", "center", "center", "flex-start"]}
            borderColor={Theme.white}
          />
        </Stack>

        {!["base", "sm", "md"].includes(breakPointValue) && <FAQImageBox />}
      </Stack>
    </Stack>
  );
}
