import {
  Button,
  Divider,
  Link,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import CreditCardIcon from "../../../../shared/vectors/CreditCardIcon";
import { Theme } from "../../../../styles/Theme";

export default function MountlyCard({ ...props }: StackProps): JSX.Element {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      minWidth={["320px", "320px", "320px", "340px"]}
      minHeight={"432px"}
      paddingTop={"24px"}
      paddingBottom={"20px"}
      borderRadius={"10px"}
      boxShadow={"0px 2px 43px rgba(0, 0, 0, 0.1)"}
      textAlign={"center"}
      backgroundColor={Theme.white}
      zIndex={"0"}
      {...props}
    >
      <Stack spacing={"36px"}>
        <Stack spacing={"0px"} alignItems={"center"}>
          <Text
            fontSize={"12px"}
            fontWeight={"medium"}
            color={Theme.mediumNeutral_1}
          >
            Plano
          </Text>
          <Text fontSize={"18px"} fontWeight={"medium"}>
            Guru Pro
          </Text>
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={Theme.mediumAquamarine}
          >
            Mensal
          </Text>
          <Divider width={"220px"} opacity={"1"} />
        </Stack>
        <Stack
          direction={"row"}
          spacing={"0px"}
          alignItems={"flex-end"}
          justifyContent={"center"}
          fontWeight={"medium"}
        >
          <Text fontSize={"48px"}>R$ 49</Text>
          <Text fontSize={"32px"} paddingBottom={"6px"}>
            ,90
          </Text>
          <Text fontSize={"18px"} paddingLeft={"8px"} paddingBottom={"12px"}>
            /mês
          </Text>
        </Stack>
      </Stack>
      <Stack spacing={"20px"} alignItems={"center"}>
        <Link href="https://www.meuguru.com/portal/aluno/pagamento/assinatura">
          <Button
            minWidth={"220px"}
            rounded={"full"}
            backgroundColor={Theme.mediumAquamarine}
            _hover={{
              backgroundColor: Theme.lightAquamarine,
            }}
            color={Theme.white}
          >
            Assinar
          </Button>
        </Link>
        <Stack direction={"row"} spacing={"16px"} alignItems={"center"}>
          <Text
            fontSize={"10px"}
            fontWeight={"medium"}
            color={Theme.mediumNeutral_1}
          >
            pague com
          </Text>
          <CreditCardIcon width={"24px"} height={"16px"} />
        </Stack>
      </Stack>
    </Stack>
  );
}
