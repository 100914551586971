import { AspectRatio, Box, Stack, Text } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";
import { ITeacherCard } from "../../types";

interface IProps {
  teacher: ITeacherCard;
}

export default function TeacherCard({ teacher }: IProps): JSX.Element {
  return (
    <AspectRatio ratio={160 / 456}>
      <Box
        width={"100%"}
        height={"100%"}
        borderRadius={"8px"}
        boxShadow={"0px 2px 43px rgba(92, 92, 92, 0.16)"}
        backgroundColor={Theme.mediumNeutral_1}
        backgroundImage={teacher.picPath}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"center center"}
      >
        <Stack
          spacing={"4px"}
          justifyContent={"flex-end"}
          paddingY={["8px", "12px", "16px", "8px", "16px"]}
          width={"100%"}
          height={"100%"}
          color={Theme.white}
          position={"relative"}
          zIndex={"1"}
        >
          <Box
            width={"100%"}
            height={"44%"}
            position={"absolute"}
            left={"0"}
            bottom={"0"}
            background={
              "linear-gradient(0.4deg, #181B1D 31.59%, rgba(45, 51, 56, 0) 99.75%)"
            }
            zIndex={"0"}
          />
          <Text
            fontSize={["8px", "12px", "16px", "12px", "16px"]}
            fontWeight={"semibold"}
            paddingX={["8px", "12px", "16px", "8px", "16px"]}
          >
            {teacher.name}
          </Text>
          <Text
            fontSize={["8px", "12px", "16px", "12px", "16px"]}
            paddingX={["8px", "12px", "16px", "8px", "16px"]}
          >
            {teacher.course}
          </Text>
          <Text
            fontSize={["7px", "10px", "14px", "10px", "14px"]}
            paddingX={["8px", "12px", "16px", "8px", "16px"]}
          >
            {teacher.universityInitial}
          </Text>
          <Stack
            direction={"row"}
            // justifyContent={"space-between"}
            alignItems={"center"}
            spacing={["4px", "4px", "8px", "4px", "8px"]}
            paddingLeft={["8px", "12px", "16px", "8px", "16px"]}
            width={"100%"}
            color={Theme.lightestNeutral_1}
          >
            <Text
              fontSize={["7px", "10px", "14px", "10px", "14px"]}
              fontWeight={"medium"}
            >
              {teacher.stars}{" "}
              <span role="img" aria-label="emoji estrela">
                ⭐
              </span>
            </Text>
            <Text fontSize={["6px", "8px", "10px", "8px", "10px"]}>
              {teacher.ratings} avaliações
            </Text>
          </Stack>
        </Stack>
      </Box>
    </AspectRatio>
  );
}
