import { AspectRatio, Box, Image, StackProps } from "@chakra-ui/react";
import faddedPic from "../../../../assets/png/hero-img-fadded.png";
import { Theme } from "../../../../styles/Theme";

export default function BannerSection({ ...props }: StackProps): JSX.Element {
  return (
    <Box
      position={"relative"}
      width={["100%", "80%", "64%", "min(924px, 64%)"]}
      display={["none", "none", "none", "initial"]}
      {...props}
    >
      <AspectRatio
        ratio={1 / 1}
        width={"62%"}
        position={"absolute"}
        top={"30%"}
        right={"-4.8%"}
      >
        <Box>
          <Box
            width={"100%"}
            height={"100%"}
            opacity={"0.1"}
            borderRadius={"50%"}
            border={`1.5px solid ${Theme.lightPurple}`}
          />
          <Box
            width={"90%"}
            height={"90%"}
            opacity={"0.25"}
            borderRadius={"50%"}
            border={`1.5px solid ${Theme.lightPurple}`}
            position={"absolute"}
            top={"18px"}
            left={"20px"}
          />
          <Box
            width={"77%"}
            height={"77%"}
            opacity={"0.45"}
            borderRadius={"50%"}
            border={`1.5px solid ${Theme.lightPurple}`}
            position={"absolute"}
            top={"42px"}
            left={"36px"}
          />
        </Box>
      </AspectRatio>
      <AspectRatio ratio={924 / 604} width={"100%"}>
        <Image
          src={faddedPic}
          width={"100%"}
          height={"100%"}
          objectFit={"contain"}
          opacity={0.8}
        />
      </AspectRatio>
    </Box>
  );
}
