import { Box, Grid, GridItem, Radio, Stack } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import { Theme } from "../../../../styles/Theme";
import { descriptionItems } from "../../types";
import DescriptionCard from "../cards/DescriptionCard";

export default function DescriptionListBox(): JSX.Element {
  return (
    <>
      <Box display={["none", "none", "block", "block"]}>
        <Grid
          templateColumns={[
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
          ]}
          gap={"40px"}
        >
          {descriptionItems.map((item, index) => (
            <GridItem w="100%" key={`descriptionItem-${index}`}>
              <DescriptionCard item={item} />
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Box
        display={["block", "block", "none", "none"]}
        width={"100%"}
        maxWidth={"500px"}
      >
        <Carousel
          infiniteLoop
          autoPlay
          interval={5000}
          showArrows={false}
          showStatus={false}
          width={"100%"}
          showThumbs={false}
          renderIndicator={(
            onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
            isSelected: boolean,
            index: number
          ) => {
            if (isSelected) {
              return (
                <Radio
                  marginX={"4px"}
                  backgroundColor={Theme.lightPurple}
                  border={`1px solid ${Theme.lightPurple} !important`}
                />
              );
            }
            return (
              <Radio
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                marginX={"4px"}
                backgroundColor={Theme.lightestNeutral_1}
                border={`1px solid ${Theme.lightNeutral_2} !important`}
              />
            );
          }}
        >
          {descriptionItems.map((item) => (
            <Stack
              alignItems={"center"}
              key="descriptionSlide1"
              height={["340px", "360px", "380px"]}
            >
              <DescriptionCard item={item} />
            </Stack>
          ))}
        </Carousel>
      </Box>
    </>
  );
}
