import { Box, BoxProps, Stack } from "@chakra-ui/react";
import { teacherCards } from "../../types";
import TeacherCard from "../cards/TeacherCard";

export default function TeachersBox({ ...props }: BoxProps): JSX.Element {
  return (
    <Box
      width={"100%"}
      maxWidth={"736px"}
      height={"100%"}
      maxHeight={"1132px"}
      {...props}
    >
      <Stack
        direction={"row"}
        spacing={["8px", "16px", "32px", "16px", "32px"]}
        justifyContent={"flex-end"}
        width={"100%"}
        height={"100%"}
      >
        <Stack
          spacing={["8px", "16px", "32px", "16px", "32px"]}
          paddingTop={"25%"}
          width={"100%"}
          height={"100%"}
        >
          <TeacherCard teacher={teacherCards[0]} />
          <TeacherCard teacher={teacherCards[7]} />
        </Stack>
        <Stack
          spacing={["8px", "16px", "32px", "16px", "32px"]}
          paddingBottom={"25%"}
          width={"100%"}
          height={"100%"}
        >
          <TeacherCard teacher={teacherCards[1]} />
          <TeacherCard teacher={teacherCards[4]} />
        </Stack>
        <Stack
          spacing={["8px", "16px", "32px", "16px", "32px"]}
          paddingTop={"25%"}
          width={"100%"}
          height={"100%"}
        >
          <TeacherCard teacher={teacherCards[2]} />
          <TeacherCard teacher={teacherCards[5]} />
        </Stack>
        <Stack
          spacing={["8px", "16px", "32px", "16px", "32px"]}
          paddingBottom={"25%"}
          width={"100%"}
          height={"100%"}
        >
          <TeacherCard teacher={teacherCards[3]} />
          <TeacherCard teacher={teacherCards[6]} />
        </Stack>
      </Stack>
    </Box>
  );
}
