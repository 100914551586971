import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 400;
        src: local('Hero New Regular'), url('./fonts/heroNew/Hero New Regular.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 800;
        src: local('Hero New ExtraBold'), url('./fonts/heroNew/Hero New ExtraBold.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 700;
        src: local('Hero New Bold'), url('./fonts/heroNew/Hero New Bold.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 600;
        src: local('Hero New SemiBold'), url('./fonts/heroNew/Hero New SemiBold.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 500;
        src: local('Hero New Medium'), url('./fonts/heroNew/Hero New Medium.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New';
        font-style: normal;
        font-weight: 300;
        src: local('Hero New Light'), url('./fonts/heroNew/Hero New Light.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Hero New Regular Italic';
        font-style: normal;
        font-weight: normal;
        src: local('Hero New Regular Italic'), url('./fonts/heroNew/Hero New Regular Italic.woff') format('woff');
        }
      /* latin */
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src: local('Inter Regular'), url('./fonts/inter/Inter-Regular.ttf') format('truetype');
        }
      /* latin */
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        src: local('Inter Bold'), url('./fonts/inter/Inter-Bold.ttf') format('truetype');
        }
    `}
  />
);

export default Fonts;
