import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
}

export default function BarCodeIcon({ width, height }: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H4V16H0V0ZM6 0H8V16H6V0ZM22 0H24V16H22V0ZM14 0H20V16H14V0ZM10 0H12V16H10V0Z"
          fill="#9B9B9F"
        />
      </svg>
    </Box>
  );
}
