import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
  color?: string;
}

export default function BackgroundWave3({
  width,
  height,
  color = "none",
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1440 527"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1466 283.018C1466 428.545 999.609 527 738.741 527C477.873 527 -44 418.786 -44 273.259C420 249.18 376.5 -0.000602473 738.741 0C1088.5 0.000581714 1071.5 233.006 1466 283.018Z"
          fill="#FAFAFA"
        />
      </svg>
    </Box>
  );
}
