import { Flex } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";
import ArrowToast from "./components/other/ArrowToast";
import HeroTitle from "./components/text/HeroTitle";
import BannerSection from "./components/sections/BannerSection";

export default function HeroContainer(): JSX.Element {
  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      width={"100%"}
      // Switch these to add video back
      minHeight={["508px", "608px", "608px", "968px"]}
      // minHeight={"968px"}
      background={`linear-gradient(336.53deg, ${Theme.darkestNeutral} 60.09%, #420099 113.43%);`}
    >
      <Flex
        direction={"column"}
        padding={"16px"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
        maxWidth="1440px"
        position="relative"
      >
        <HeroTitle
          position={["relative", "relative", "relative", "absolute"]}
          marginTop={["124px", "124px", "124px", "0px"]}
          top={["0px", "0px", "0px", "277px"]}
          left={["0px", "0px", "0px", "120px"]}
          zIndex={1}
        />

        {/* Switch these to add video back */}
        <BannerSection
          position={["relative", "relative", "relative", "absolute"]}
          marginTop={["80px", "80px", "80px", "0px"]}
          marginBottom={["120px", "120px", "120px", "0px"]}
          top={["0px", "0px", "0px", "200px", "120px"]}
          right={["0px", "0px", "0px", "0px"]}
        />
        {/* <VideoSection
          position={["relative", "relative", "relative", "absolute"]}
          marginTop={["80px", "80px", "80px", "0px"]}
          marginBottom={["120px", "120px", "120px", "0px"]}
          top={["0px", "0px", "0px", "200px", "120px"]}
          right={["0px", "0px", "0px", "0px"]}
        /> */}
      </Flex>

      <ArrowToast />
    </Flex>
  );
}
