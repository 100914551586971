import { Icon, SlideFade, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { TiChevronLeft } from "react-icons/ti";
import { Theme } from "../../../../styles/Theme";

export default function ArrowToast(): JSX.Element {
  const [isHidden, setIsHidden] = useState(false);

  window.addEventListener("scroll", onScroll);

  function onScroll() {
    setIsHidden(true);
  }

  return (
    <Stack
      alignSelf={"center"}
      position={"fixed"}
      bottom={"40px"}
      zIndex={"999"}
    >
      <SlideFade in={!isHidden} reverse unmountOnExit delay={1.5}>
        <Icon
          as={TiChevronLeft}
          boxSize={"48px"}
          color={Theme.mediumYellow}
          transform={"rotate(-90deg)"}
        />
      </SlideFade>
    </Stack>
  );
}
