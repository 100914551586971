import { Stack, StackProps, Text } from "@chakra-ui/react";
import WannaBeProButton from "../../../../shared/buttons/WannaBeProButton";
import { Theme } from "../../../../styles/Theme";

export default function TeachersTitle({ ...props }: StackProps): JSX.Element {
  return (
    <Stack
      spacing={"32px"}
      paddingX={"16px"}
      maxWidth={"540px"}
      zIndex={"1"}
      {...props}
    >
      <Stack spacing={"0px"}>
        <Text fontSize={["18px", "18px", "20px", "24px"]} fontWeight={"medium"}>
          Apareça como PRO para
        </Text>
        <Stack>
          <Text
            fontSize={["36px", "36px", "48px", "56px"]}
            fontWeight={"semibold"}
          >
            <Text as={"span"} fontWeight={"bold"} color={Theme.darkAquamarine}>
              GURUS
            </Text>{" "}
            QUE VOCÊ JÁ CONHECE
          </Text>
          <Text
            fontSize={"18px"}
            fontWeight={"medium"}
            color={Theme.mediumNeutral_1}
          >
            Tutores que já te ajudaram saberão quem você é! <br /> Dê boosts em
            seus pedidos, convide-os e receba orçamentos com desconto em todos
            seus pedidos!
          </Text>
        </Stack>
      </Stack>
      <WannaBeProButton alignSelf={["center", "center", "center", "normal"]} />
    </Stack>
  );
}
