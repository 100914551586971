import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
}

export default function PixIcon({ width, height }: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4748 12.2386C12.1662 12.2397 11.8605 12.1796 11.5753 12.0619C11.2901 11.9442 11.031 11.7711 10.8131 11.5527L8.41279 9.1509C8.32789 9.06972 8.21495 9.02441 8.09749 9.02441C7.98002 9.02441 7.86708 9.06972 7.78218 9.1509L5.37379 11.5593C5.15599 11.778 4.897 11.9515 4.61179 12.0695C4.32657 12.1876 4.02079 12.248 3.7121 12.2472H3.24219L6.28372 15.2887C6.73989 15.7439 7.358 15.9996 8.00244 15.9996C8.64687 15.9996 9.26499 15.7439 9.72116 15.2887L12.7688 12.2406L12.4748 12.2386Z"
          fill="#9B9B9F"
        />
        <path
          d="M3.71261 3.7511C4.0213 3.75028 4.32709 3.81065 4.61231 3.92872C4.89753 4.04679 5.15652 4.22022 5.3743 4.43899L7.78269 6.84789C7.8664 6.93134 7.97979 6.97821 8.09799 6.97821C8.2162 6.97821 8.32959 6.93134 8.4133 6.84789L10.8131 4.44812C11.0307 4.22907 11.2896 4.05543 11.5749 3.93726C11.8601 3.81908 12.166 3.75874 12.4748 3.75972H12.7637L9.71609 0.712104C9.49041 0.486345 9.22247 0.307258 8.92757 0.185073C8.63267 0.0628884 8.31658 0 7.99737 0C7.67816 0 7.36207 0.0628884 7.06717 0.185073C6.77227 0.307258 6.50433 0.486345 6.27865 0.712104L3.24219 3.7511H3.71261Z"
          fill="#9B9B9F"
        />
        <path
          d="M15.2879 6.2814L13.4462 4.43975C13.4047 4.45673 13.3603 4.46567 13.3155 4.46611H12.478C12.0422 4.4672 11.6245 4.64027 11.3157 4.94769L8.91588 7.34593C8.69977 7.56166 8.40688 7.68282 8.10151 7.68282C7.79615 7.68282 7.50326 7.56166 7.28714 7.34593L4.87825 4.93856C4.56947 4.63096 4.15173 4.4577 3.71588 4.45648H2.68784C2.64547 4.45553 2.6036 4.44712 2.56415 4.43164L0.710846 6.2814C0.255647 6.73757 0 7.35568 0 8.00012C0 8.64455 0.255647 9.26267 0.710846 9.71883L2.5601 11.5681C2.59946 11.5523 2.64139 11.5439 2.68379 11.5432H3.71588C4.15172 11.542 4.56944 11.3687 4.87825 11.0612L7.28664 8.65278C7.72208 8.21784 8.48095 8.21784 8.91588 8.65278L11.3157 11.052C11.6245 11.3595 12.0422 11.5325 12.478 11.5336H13.3155C13.3603 11.5339 13.4047 11.5429 13.4462 11.56L15.2879 9.71833C15.5137 9.49265 15.6927 9.22471 15.8149 8.92981C15.9371 8.63491 16 8.31882 16 7.99961C16 7.6804 15.9371 7.36431 15.8149 7.06941C15.6927 6.77451 15.5137 6.50657 15.2879 6.28089"
          fill="#9B9B9F"
        />
      </svg>
    </Box>
  );
}
