import { Stack, StackProps, Text } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";

export default function OffersTitle({ ...props }: StackProps): JSX.Element {
  return (
    <Stack
      spacing={"4px"}
      alignItems={"center"}
      textAlign={"center"}
      color={Theme.lightestNeutral_1}
      {...props}
    >
      <Text fontSize={["32px", "40px", "48px", "56px"]}>
        PREÇO{" "}
        <Text
          as={"span"}
          fontWeight={"bold"}
          color={[Theme.darkYellow, Theme.darkYellow, "inherit"]}
        >
          EXCLUSIVO
        </Text>
      </Text>
      <Text fontSize={["20px", "24px", "28px", "32px"]}>
        PARA QUEM JÁ É CADASTRADO
      </Text>
      <Text
        fontSize={["16px", "16px", "18px", "20px"]}
        fontWeight={"light"}
        color={Theme.lightNeutral_2}
      >
        Dê um upgrade. Pague menos por mais pedidos.
      </Text>
    </Stack>
  );
}
