import { Icon, Stack, Text } from "@chakra-ui/react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Theme } from "../../../../styles/Theme";

interface IProps {
  label: string;
  isOpen: boolean;
  onClick: () => void;
}

export default function FAQButton({
  label,
  isOpen,
  onClick,
}: IProps): JSX.Element {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      fontWeight={isOpen ? "bold" : "normal"}
      padding={"16px 8px"}
      backgroundColor={isOpen ? Theme.darkNeutral : "inherit"}
      _hover={{ backgroundColor: Theme.darkNeutral, cursor: "pointer" }}
      onClick={onClick}
    >
      <Text>{label}</Text>
      <Icon as={isOpen ? FaMinus : FaPlus} />
    </Stack>
  );
}
