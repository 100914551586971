import { ChakraProvider } from "@chakra-ui/react";
import Container from "./containers/Container";
import theme from "./Theme";

import HeaderContainer from "./containers/header/HeaderContainer";
import HeroContainer from "./containers/hero/HeroContainer";
import DescriptionContainer from "./containers/description/DescriptionContainer";
import OffersAndReviewsContainer from "./containers/offers/OffersAndReviewsContainer";
import TeachersContainer from "./containers/teachers/TeachersContainer";
import FAQContainer from "./containers/faq/FAQContainer";
import FooterContainer from "./containers/footer/FooterContainer";
import Copyright from "./containers/faq/components/Copyright";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Container>
      <HeaderContainer />
      <HeroContainer />
      <DescriptionContainer />
      <OffersAndReviewsContainer />
      <TeachersContainer />
      <FAQContainer />
      <FooterContainer />
      <Copyright />
    </Container>
  </ChakraProvider>
);
