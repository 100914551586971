import { Box } from "@chakra-ui/react";

interface IProps {
  width: string;
  height: string;
  color?: string;
}

export default function BackgroundWave1({
  width,
  height,
  color = "none",
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 1440 355`}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M172.675 1.71646C-133.462 15.3778 -303.839 174.282 -286.838 278.908L-64.3314 302.46L1522.21 355V328.73C1661.88 271.208 1907.47 -7.80577 1375.21 69.2034C706.193 166 649.689 -19.5702 172.675 1.71646Z"
          fill="white"
        />
      </svg>
    </Box>
  );
}
