import { AspectRatio, Box, Stack } from "@chakra-ui/react";
import WannaBeProButton from "../../shared/buttons/WannaBeProButton";
import BackgroundWave1 from "../../shared/vectors/BackgroundWave1";
import DescriptionListBox from "./components/boxes/DescriptionListBox";
import DescriptionTitle from "./components/text/DescriptionTitle";

export default function DescriptionContainer(): JSX.Element {
  return (
    <Stack spacing={"0px"} alignItems={"center"} position={"relative"}>
      <Box
        width={"100vw"}
        minWidth={"500px"}
        position={"absolute"}
        transform={"translateY(-50%)"}
      >
        <AspectRatio ratio={1440 / 355}>
          <BackgroundWave1 width="100%" height="100%" />
        </AspectRatio>
      </Box>

      <Stack
        spacing={"84px"}
        alignItems={"center"}
        width={"100%"}
        paddingX={["16px", "16px", "16px", "90px"]}
        zIndex={2}
      >
        <DescriptionTitle />
        <DescriptionListBox />
        <WannaBeProButton />
      </Stack>
    </Stack>
  );
}
