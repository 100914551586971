export default function LogoFooter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="48"
      viewBox="0 0 155 48"
      fill="none"
    >
      <g clip-path="url(#clip0_33_1361)">
        <path
          d="M12.0596 23.6361C12.464 23.6361 12.8499 23.729 13.2068 23.9002C15.0432 22.101 16.7785 20.0294 17.7792 17.9928C17.1809 17.2768 16.0089 16.4556 15.5879 16.1255C12.5115 19.8457 6.54628 25.4705 0.845215 30.0305C1.17948 31.2293 1.77579 32.0938 2.82398 32.7789C2.82398 32.7789 5.03178 32.1681 6.54422 31.1798C7.29941 30.6846 8.18666 30.0491 9.05946 29.1969C8.90677 28.7285 8.82217 28.2168 8.82217 27.6824C8.82217 25.4519 10.2748 23.6382 12.0596 23.6382V23.6361Z"
          fill="#111827"
        />
        <path
          d="M14.5914 15.1866C14.0838 14.9431 13.537 14.8379 12.9654 14.8379C11.0733 14.8379 8.92538 16.0058 7.18596 17.2396C5.2237 18.6304 0.628595 21.8905 -0.143103 25.1836C-0.38039 26.1926 -0.229764 27.3542 0.292267 28.6335C5.4775 24.156 12.4702 17.8277 14.5914 15.1866Z"
          fill="#111827"
        />
        <path
          d="M24.1881 25.1815C23.763 23.3678 22.1721 21.5685 20.5091 20.0747C19.954 19.5753 18.9223 18.9192 18.9223 18.9192C18.9223 18.9192 18.3178 19.9942 17.7359 20.8092C16.8239 22.0865 16.5784 22.4393 16.5784 22.4393C16.5784 22.4393 17.771 23.3864 18 23.5948C20.0262 25.4332 22.2464 27.3336 23.7506 28.6314C24.2726 27.3522 24.4212 26.1905 24.186 25.1815H24.1881Z"
          fill="#111827"
        />
        <path
          d="M14.3829 24.9216C14.9772 25.6107 15.297 26.6486 15.297 27.6803C15.297 28.2374 15.2062 28.7677 15.0432 29.2505C15.8974 30.0779 16.762 30.6969 17.5027 31.1797C19.0152 32.1681 21.223 32.7788 21.223 32.7788C22.2712 32.0938 22.8675 31.2293 23.2017 30.0304C21.7326 28.8564 19.9994 27.3378 18.3528 25.8955C17.4883 25.1382 15.656 23.5474 15.656 23.5474C15.656 23.5474 15.5322 23.7331 15.1876 24.0983C14.9586 24.3418 14.6883 24.6121 14.385 24.9236L14.3829 24.9216Z"
          fill="#111827"
        />
        <path
          d="M13.5638 26.207C13.2316 25.5116 12.6766 25.0474 12.0617 25.0474C12.0369 25.0474 12.0142 25.0474 11.9915 25.0494C11.0321 25.1051 10.2356 26.2874 10.2356 27.6802C10.2356 27.7359 10.2356 27.7916 10.2397 27.8473C10.3016 29.1988 11.1105 30.3131 12.0617 30.3131C13.0129 30.3131 13.7784 29.2587 13.8774 27.9526C13.8836 27.8618 13.8877 27.771 13.8877 27.6802C13.8877 27.1396 13.7681 26.632 13.5638 26.207Z"
          fill="#111827"
        />
        <path
          d="M35.1301 16.5586H38.1612L38.2891 18.7829C38.741 17.9225 39.3951 17.256 40.2555 16.7835C41.1159 16.311 42.1043 16.0737 43.2226 16.0737C45.5439 16.0737 47.0914 17.0621 47.8652 19.0408C48.3604 18.0958 49.064 17.3633 49.9781 16.8475C50.8922 16.3317 51.961 16.0737 53.1866 16.0737C54.9714 16.0737 56.3559 16.6267 57.3463 17.7347C58.3347 18.8428 58.8299 20.4047 58.8299 22.4268V31.551H55.5739V22.8127C55.5739 21.5437 55.3098 20.5822 54.7836 19.926C54.2575 19.2699 53.4982 18.9418 52.5098 18.9418C51.3275 18.9418 50.3866 19.3834 49.6892 20.2644C48.9897 21.1455 48.641 22.3278 48.641 23.8113V31.5489H45.385V22.8106C45.385 20.2314 44.343 18.9418 42.257 18.9418C41.0747 18.9418 40.1338 19.3834 39.4364 20.2644C38.7369 21.1455 38.3882 22.3278 38.3882 23.8113V31.5489H35.1322V16.5566L35.1301 16.5586Z"
          fill="#111827"
        />
        <path
          d="M60.3424 24.0712C60.3424 22.5237 60.6643 21.1474 61.3101 19.9445C61.956 18.7415 62.8411 17.7945 63.9698 17.1074C65.0985 16.4203 66.3922 16.0757 67.8551 16.0757C69.318 16.0757 70.6324 16.4244 71.7404 17.1239C72.8464 17.8234 73.713 18.8014 74.3361 20.058C74.9593 21.3146 75.2708 22.7713 75.2708 24.4261V25.0389H63.5984C63.7284 26.3512 64.2277 27.3829 65.0985 28.134C65.9692 28.8871 67.1247 29.2626 68.5649 29.2626C70.3064 29.2626 71.8642 28.6622 73.2405 27.4572L74.852 29.4875C74.0782 30.284 73.1208 30.9009 71.9818 31.3404C70.8429 31.7799 69.6379 32.0007 68.3709 32.0007C66.7801 32.0007 65.3832 31.6664 64.1803 31.002C62.9753 30.3356 62.0364 29.4071 61.3596 28.2124C60.6829 27.0198 60.3445 25.6394 60.3445 24.0691L60.3424 24.0712ZM70.7727 19.9115C70.0526 19.2244 69.1117 18.8798 67.9521 18.8798C66.7925 18.8798 65.7587 19.2285 64.985 19.928C64.2112 20.6275 63.749 21.5684 63.5984 22.7486H72.0458C71.9158 21.5457 71.4928 20.5986 70.7727 19.9115Z"
          fill="#111827"
        />
        <path
          d="M77.2701 25.6829V16.5587H80.5261V25.231C80.5261 26.5 80.8005 27.4718 81.3473 28.1486C81.8962 28.8254 82.6741 29.1638 83.6851 29.1638C84.9314 29.1638 85.9259 28.6954 86.6667 27.7607C87.4074 26.826 87.7788 25.5839 87.7788 24.0363V16.5566H91.0348V31.549H88.0368L87.9088 29.2608C87.393 30.1212 86.6894 30.7918 85.7959 31.2767C84.9046 31.7595 83.8997 32.003 82.7814 32.003C81.0605 32.003 79.7132 31.45 78.7351 30.342C77.7571 29.236 77.2681 27.6823 77.2681 25.6829H77.2701Z"
          fill="#111827"
        />
        <path
          d="M108.062 31.3901V29.5516C107.03 31.0145 105.45 31.745 103.322 31.745C101.969 31.745 100.78 31.4334 99.7588 30.8103C98.7374 30.1871 97.943 29.3164 97.3735 28.198C96.804 27.0797 96.5193 25.7798 96.5193 24.2962C96.5193 22.6187 96.8845 21.1682 97.6149 19.9425C98.3454 18.7169 99.3729 17.7657 100.693 17.0889C102.016 16.4121 103.568 16.0737 105.353 16.0737C106.32 16.0737 107.329 16.1645 108.384 16.3482C109.436 16.5318 110.393 16.7938 111.254 17.1384V31.2601C111.254 33.5607 110.587 35.3332 109.254 36.5794C107.921 37.8257 106.009 38.4488 103.516 38.4488C101.024 38.4488 98.9912 37.8154 97.357 36.5464L98.7766 34.1612C100.217 35.1928 101.775 35.7087 103.452 35.7087C106.527 35.7087 108.064 34.2685 108.064 31.388L108.062 31.3901ZM107.998 24.7151V19.2658C107.073 19.0285 106.213 18.9109 105.419 18.9109C103.656 18.9109 102.286 19.3792 101.308 20.3139C100.33 21.2486 99.8413 22.5444 99.8413 24.1993C99.8413 25.6395 100.217 26.795 100.97 27.6657C101.723 28.5364 102.722 28.9718 103.968 28.9718C105.214 28.9718 106.145 28.5736 106.886 27.7792C107.626 26.9848 107.998 25.9634 107.998 24.7151Z"
          fill="#111827"
        />
        <path
          d="M113.445 25.6829V16.5587H116.701V25.231C116.701 26.5 116.975 27.4718 117.522 28.1486C118.071 28.8254 118.849 29.1638 119.86 29.1638C121.106 29.1638 122.101 28.6954 122.842 27.7607C123.582 26.826 123.954 25.5839 123.954 24.0363V16.5566H127.21V31.549H124.212L124.084 29.2608C123.568 30.1212 122.864 30.7918 121.971 31.2767C121.079 31.7595 120.075 32.003 118.956 32.003C117.235 32.003 115.888 31.45 114.91 30.342C113.932 29.236 113.443 27.6823 113.443 25.6829H113.445Z"
          fill="#111827"
        />
        <path
          d="M129.469 16.5587H132.5L132.661 19.01C133.177 18.1496 133.848 17.4831 134.677 17.0106C135.504 16.5381 136.423 16.3008 137.434 16.3008C137.993 16.3008 138.552 16.3874 139.111 16.5587L138.498 19.5258C138.047 19.3752 137.574 19.3009 137.079 19.3009C135.833 19.3009 134.795 19.7095 133.967 20.5265C133.14 21.3436 132.725 22.4187 132.725 23.7516V31.5532H129.469V16.5608V16.5587Z"
          fill="#111827"
        />
        <path
          d="M140.82 25.6829V16.5587H144.076V25.231C144.076 26.5 144.35 27.4718 144.897 28.1486C145.446 28.8254 146.224 29.1638 147.235 29.1638C148.481 29.1638 149.475 28.6954 150.216 27.7607C150.957 26.826 151.328 25.5839 151.328 24.0363V16.5566H154.584V31.549H151.586L151.458 29.2608C150.943 30.1212 150.239 30.7918 149.346 31.2767C148.454 31.7595 147.449 32.003 146.331 32.003C144.61 32.003 143.263 31.45 142.285 30.342C141.307 29.236 140.818 27.6823 140.818 25.6829H140.82Z"
          fill="#111827"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_1361">
          <rect width="154.4" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
