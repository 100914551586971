import { Text } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";
import GabrielCortez from "../../assets/png/reviewPics/GabrielCortez.png";
import EyllaIris from "../../assets/png/reviewPics/EyllaIris.png";
import CarlosMendes from "../../assets/png/reviewPics/CarlosMendes.png";
import LuanaSantos from "../../assets/png/reviewPics/LuanaSantos.png";
import LuizaCoutinho from "../../assets/png/reviewPics/LuizaCoutinho.png";

export interface IReview {
  description: JSX.Element;
  name: string;
  picPath: string;
}

export const reviews: IReview[] = [
  {
    description: (
      <Text>
        Eu amo a Meu Guru, com certeza é uma{" "}
        <Text as={"span"} fontWeight={"semibold"} color={Theme.mediumPurple_2}>
          ferramenta essencial
        </Text>{" "}
        para qualquer universitário, me ajuda muito nas provas e trabalhos!
      </Text>
    ),
    name: "Gabriel Cortez",
    picPath: GabrielCortez,
  },
  {
    description: (
      <Text>
        Sem palavras!{" "}
        <Text as={"span"} fontWeight={"semibold"} color={Theme.mediumPurple_2}>
          Já fui salva
        </Text>{" "}
        muitas vezes da reprovação pela Meu Guru. Muito útil!
      </Text>
    ),
    name: "Eylla Iris",
    picPath: EyllaIris,
  },
  {
    description: (
      <Text>
        Eu sempre convidava tutores que já tinham feito trabalhos comigo para
        trabalhos novos por já entenderem o que eu preciso.{" "}
        <Text as={"span"} fontWeight={"semibold"} color={Theme.mediumPurple_2}>
          Só me ajudou mais!
        </Text>
      </Text>
    ),
    name: "Carlos Mendes",
    picPath: CarlosMendes,
  },
  {
    description: (
      <Text>
        Passei a mandar todos os meus pedidos{" "}
        <Text as={"span"} fontWeight={"semibold"} color={Theme.mediumPurple_2}>
          para o mesmo guru
        </Text>{" "}
        através do guru favorito, facilitou demais a nossa comunicação e foi
        muito mais fácil de enviar.
      </Text>
    ),
    name: "Luana Santos",
    picPath: LuanaSantos,
  },
  {
    description: (
      <Text>
        Notei que meus pedidos começaram a receber{" "}
        <Text as={"span"} fontWeight={"semibold"} color={Theme.mediumPurple_2}>
          mais propostas
        </Text>{" "}
        por conta dos boosts (da assinatura) e por conta do Selo do Clube.
      </Text>
    ),
    name: "Luiza Coutinho",
    picPath: LuizaCoutinho,
  },
];
