import { AspectRatio, Box, Stack, Text } from "@chakra-ui/react";
import BackgroundWave2 from "../../shared/vectors/BackgroundWave2";
import BackgroundWave3 from "../../shared/vectors/BackgroundWave3";
import { Theme } from "../../styles/Theme";
import OffersListBox from "./components/boxes/OffersListBox";
import ReviewsBox from "./components/boxes/ReviewsBox";
import OffersTitle from "./components/text/OffersTitle";

export default function OffersAndReviewsContainer(): JSX.Element {
  return (
    <Stack spacing={"0px"} alignItems={"center"} position={"relative"}>
      <Stack
        spacing={"0px"}
        alignItems={"center"}
        position={"relative"}
        zIndex={"99"}
      >
        <Box width={"100vw"} minWidth={"700px"}>
          <AspectRatio ratio={1440 / 788}>
            <BackgroundWave2 width="100%" height="100%" />
          </AspectRatio>
        </Box>
        <OffersTitle
          width={"100%"}
          maxWidth={["320px", "600px"]}
          position={"absolute"}
          top={["25%", "25%", "25%", "25%", "30%"]}
        />
        <Box
          width={"100vw"}
          minWidth={"600px"}
          position={"absolute"}
          top={"62%"}
        >
          <AspectRatio ratio={1440 / 527}>
            <BackgroundWave3 width="100%" height="100%" />
          </AspectRatio>
        </Box>
      </Stack>
      <Stack
        spacing={["172px", "172px", "172px", "60px"]}
        alignItems={"center"}
        position={"relative"}
        width={"100vw"}
        paddingBottom={["80px", "80px", "80px", "448px"]}
        background={
          "linear-gradient(351.48deg, #F2F2F2 52.98%, rgba(242, 242, 242, 0) 82.07%)"
        }
      >
        <OffersListBox
          position={"relative"}
          top={["-60px", "-60px", "-60px", "calc(-23vw * 788 / 1440)"]}
          zIndex={"99"}
        />

        <Stack
          spacing={["52px", "52px", "52px", "68px"]}
          alignItems={"center"}
          paddingX={"16px"}
        >
          <Text
            textAlign={"center"}
            maxWidth={["324px", "400px", "548px"]}
            fontSize={["24px", "28px", "32px"]}
            color={Theme.mediumNeutral_1}
          >
            O que os alunos PRO falam:
          </Text>
          <ReviewsBox />
        </Stack>
      </Stack>
    </Stack>
  );
}
