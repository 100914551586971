import {
  Button,
  Divider,
  Link,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import BarCodeIcon from "../../../../shared/vectors/BarCodeIcon";
import CreditCardIcon from "../../../../shared/vectors/CreditCardIcon";
import PixIcon from "../../../../shared/vectors/PixIcon";
import { Theme } from "../../../../styles/Theme";

export default function YearlyCard({ ...props }: StackProps): JSX.Element {
  return (
    <Stack spacing={"-18px"} alignItems={"center"} {...props}>
      <Stack
        alignItems={"center"}
        width={"100%"}
        maxWidth={"200px"}
        padding={"8px"}
        backgroundColor={Theme.mediumYellow}
        borderRadius={"4px"}
        fontSize={"14px"}
        fontWeight={"bold"}
        color={Theme.darkestNeutral}
        zIndex={"100"}
      >
        <Text>MAIS ESCOLHIDO</Text>
      </Stack>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        minWidth={["320px", "320px", "320px", "380px"]}
        minHeight={"540px"}
        paddingTop={"72px"}
        paddingBottom={"20px"}
        backgroundColor={Theme.darkestNeutral}
        color={Theme.white}
        borderRadius={"10px"}
        boxShadow={"0px 2px 43px rgba(0, 0, 0, 0.1)"}
        position={"relative"}
        textAlign={"center"}
      >
        <Stack spacing={"0px"}>
          <Text fontSize={"12px"} fontWeight={"medium"}>
            Plano
          </Text>
          <Text fontSize={"18px"} fontWeight={"medium"}>
            Guru Pro
          </Text>
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={Theme.mediumYellow}
          >
            Anual
          </Text>
          <Divider width={"220px"} opacity={"1"} />
        </Stack>
        <Stack spacing={"20px"} alignItems={"center"}>
          <Stack spacing={"-8px"} width={"max-content"}>
            <Text
              alignSelf={"flex-start"}
              fontSize={"14px"}
              color={Theme.lightNeutral_5}
            >
              12x de
            </Text>
            <Stack
              direction={"row"}
              spacing={"0px"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              fontWeight={"medium"}
            >
              <Text fontSize={"48px"}>R$ 17</Text>
              <Text fontSize={"32px"} paddingBottom={"6px"}>
                ,90
              </Text>
              <Text
                fontSize={"18px"}
                paddingLeft={"8px"}
                paddingBottom={"12px"}
              >
                /mês
              </Text>
            </Stack>
            <Text
              alignSelf={"flex-end"}
              fontSize={"14px"}
              fontWeight={"light"}
              color={Theme.lightNeutral_5}
            >
              = 214,80/ano
            </Text>
          </Stack>
          <Text
            fontSize={"12px"}
            fontWeight={"medium"}
            color={Theme.mediumAquamarine}
          >
            economize <b>R$ 384,00 por ano (65% OFF)</b>
          </Text>
        </Stack>
        <Stack spacing={"36px"} alignItems={"center"}>
          <Link href="https://www.meuguru.com/portal/aluno/pagamento/assinatura">
            <Button
              minWidth={"220px"}
              rounded={"full"}
              backgroundColor={Theme.mediumYellow}
              border={`1px solid ${Theme.white}`}
              _hover={{ backgroundColor: Theme.lightestYellow }}
              color={Theme.darkestNeutral}
            >
              Assinar
            </Button>
          </Link>
          <Stack direction={"row"} spacing={"16px"} alignItems={"center"}>
            <Text
              fontSize={"10px"}
              fontWeight={"medium"}
              color={Theme.lightNeutral_3}
            >
              pague com
            </Text>
            <Stack direction={"row"} alignItems={"center"}>
              <PixIcon width={"16px"} height={"16px"} />
              <CreditCardIcon width={"24px"} height={"16px"} />
              <BarCodeIcon width={"24px"} height={"16px"} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
