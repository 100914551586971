import { Heading, Stack, StackProps } from "@chakra-ui/react";
import WannaBeProButton from "../../../../shared/buttons/WannaBeProButton";
import { Theme } from "../../../../styles/Theme";

export default function HeroTitle({ ...props }: StackProps): JSX.Element {
  return (
    <Stack spacing={"32px"} maxWidth={["380px", "400px", "508px"]} {...props}>
      <Heading
        color={Theme.lightestNeutral_1}
        fontSize={["30px", "32px", "40px"]}
        fontWeight={"normal"}
      >
        O SERVIÇO EDUCACIONAL MAIS{" "}
        <Heading as={"span"} fontSize={"inherit"} color={Theme.mediumYellow}>
          EXCLUSIVO
        </Heading>{" "}
        DA INTERNET NA VERSÃO PRO
      </Heading>
      <WannaBeProButton
        alignSelf={["center", "center", "center", "normal"]}
        fontSize={"16px"}
        borderColor={Theme.white}
        padding={"20px 12px"}
      />
    </Stack>
  );
}
