import { Box, Stack } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";
import { IDescriptionItem } from "../../types";

interface IProps {
  item: IDescriptionItem;
}

export default function DescriptionCard({ item }: IProps): JSX.Element {
  return (
    <Stack
      spacing={["16px", "20px", "24px"]}
      alignItems={"center"}
      maxWidth={["240px", "268px", "300px"]}
    >
      {item.image}
      <Stack
        alignItems={"center"}
        textAlign={"center"}
        fontSize={["16px", "18px", "20px"]}
      >
        <Box fontWeight={"medium"}>{item.title}</Box>

        <Box color={Theme.darkNeutral} fontSize={["14px", "16px", "18px"]}>
          {item.description}
        </Box>
      </Stack>
    </Stack>
  );
}
