import { Box, Grid, GridItem, Icon, Radio, Stack } from "@chakra-ui/react";
import { AiFillLike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import { Theme } from "../../../../styles/Theme";
import { reviews } from "../../types";
import ReviewCard from "../cards/ReviewCard";

export default function ReviewsBox(): JSX.Element {
  return (
    <>
      <Box
        paddingX={"16px"}
        display={["none", "none", "none", "block"]}
        maxWidth={"1100px"}
        position={"relative"}
      >
        <Grid
          templateRows={"repeat(2, 1fr)"}
          templateColumns={"repeat(20, 1fr)"}
          gap={"16px"}
        >
          <GridItem colSpan={7}>
            <ReviewCard review={reviews[0]} />
          </GridItem>
          <GridItem colSpan={6}>
            <ReviewCard review={reviews[1]} />
          </GridItem>
          <GridItem colSpan={7}>
            <ReviewCard review={reviews[2]} />
          </GridItem>
          <GridItem colSpan={10}>
            <ReviewCard review={reviews[3]} />
          </GridItem>
          <GridItem colSpan={10}>
            <ReviewCard review={reviews[4]} />
          </GridItem>
        </Grid>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"48px"}
          height={"48px"}
          backgroundColor={"#FF3B2D"}
          borderRadius={"100%"}
          position={"absolute"}
          top={"204px"}
          left={"-8px"}
        >
          <Icon as={FaHeart} boxSize={"20px"} color={Theme.white} />
        </Stack>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"48px"}
          height={"48px"}
          backgroundColor={"#557AD8"}
          borderRadius={"100%"}
          position={"absolute"}
          bottom={"100px"}
          right={"-8px"}
        >
          <Icon as={AiFillLike} boxSize={"20px"} color={Theme.white} />
        </Stack>
      </Box>
      <Box
        display={["block", "block", "block", "none"]}
        width={"100%"}
        maxWidth={"280px"}
      >
        <Carousel
          infiniteLoop
          autoPlay
          interval={5000}
          showArrows={false}
          showStatus={false}
          width={"100%"}
          showThumbs={false}
          renderIndicator={(
            onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
            isSelected: boolean,
            index: number
          ) => {
            if (isSelected) {
              return (
                <Radio
                  marginX={"4px"}
                  backgroundColor={Theme.lightPurple}
                  border={`1px solid ${Theme.lightPurple} !important`}
                />
              );
            }
            return (
              <Radio
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                marginX={"4px"}
                backgroundColor={Theme.lightestNeutral_1}
                border={`1px solid ${Theme.lightNeutral_2} !important`}
              />
            );
          }}
        >
          {reviews.map((review) => (
            <Stack alignItems={"center"} key={review.name} height={"500px"}>
              <ReviewCard review={review} />
            </Stack>
          ))}
        </Carousel>
      </Box>
    </>
  );
}
