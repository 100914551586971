import { Box } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";

interface IProps {
  width: string;
  height: string;
  color?: string;
}

export default function GraduationCapIcon({
  width,
  height,
  color = Theme.mediumYellow,
}: IProps): JSX.Element {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 33 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1869 13.6027L14.1871 13.6028C14.8979 13.8518 15.6446 13.9775 16.4049 13.9775C17.1653 13.9775 17.912 13.8518 18.6227 13.6028L18.6229 13.6027L25.8536 11.0675V14.6616C25.8536 14.9538 25.7089 15.3077 25.2863 15.7081C24.8621 16.1101 24.208 16.5067 23.3376 16.8555C21.6009 17.5514 19.1502 17.9986 16.4051 17.9986C13.6599 17.9986 11.2094 17.5514 9.47284 16.8555C8.60252 16.5067 7.94849 16.1101 7.52427 15.7082C7.10171 15.3077 6.95703 14.9539 6.95703 14.6616V11.0677L14.1869 13.6027Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M31.9957 6.00561L31.9958 6.00565C32.0136 6.0119 32.0268 6.01913 32.0362 6.02567C32.0269 6.03214 32.0138 6.03929 31.9961 6.04549L31.996 6.04553L17.6105 11.0892L17.6105 11.0892C16.8349 11.3611 15.9677 11.3611 15.1921 11.0892L15.1921 11.0892L4.73968 7.42424L3.74152 7.07425V8.13199V12.5279V12.9515L4.10428 13.1702C4.36286 13.3261 4.48499 13.5554 4.48499 13.7708C4.48499 13.9691 4.3827 14.1763 4.16572 14.331L3.75575 14.6235L3.87122 15.1136L4.48651 17.7253C4.48651 17.7254 4.48652 17.7254 4.48653 17.7254C4.50893 17.8209 4.48774 17.9343 4.39136 18.0391L4.39123 18.0392C4.28912 18.1503 4.11221 18.2358 3.90009 18.2358H3.17038C2.95838 18.2358 2.78133 18.1503 2.67925 18.0392L2.67895 18.0389C2.58255 17.9342 2.56148 17.821 2.58387 17.7258C2.58388 17.7257 2.58389 17.7257 2.58389 17.7257L3.19908 15.1136L3.31449 14.6235L2.9047 14.3311C2.68777 14.1763 2.58549 13.9693 2.58549 13.7708C2.58549 13.5555 2.70765 13.3261 2.96619 13.1702L3.32895 12.9515V12.5279V7.4615V6.92968L2.82708 6.75373L0.807207 6.04562C0.789296 6.03934 0.776077 6.03207 0.766622 6.02551C0.776006 6.01903 0.789103 6.01186 0.806828 6.00565L0.806933 6.00561L15.2381 0.946062C15.2381 0.946051 15.2382 0.946041 15.2382 0.946031C15.9841 0.684647 16.8186 0.684666 17.5643 0.946032C17.5643 0.946037 17.5643 0.946043 17.5643 0.946048L31.9957 6.00561Z"
          stroke={color}
          strokeWidth="1.5"
        />
      </svg>
    </Box>
  );
}
