import { Text } from "@chakra-ui/react";
import { Theme } from "../../styles/Theme";
import Item1Vector from "./components/vectors/Item1Vector";
import Item2Vector from "./components/vectors/Item2Vector";
import Item3Vector from "./components/vectors/Item3Vector";
import Item4Vector from "./components/vectors/Item4Vector";
import Item5Vector from "./components/vectors/Item5Vector";
import Item6Vector from "./components/vectors/Item6Vector";
import Item7Vector from "./components/vectors/Item7Vector";
import Item8Vector from "./components/vectors/Item8Vector";

export interface IDescriptionItem {
  image: JSX.Element;
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export const descriptionItems: IDescriptionItem[] = [
  {
    image: <Item1Vector />,
    title: (
      <Text>
        <Text as={"span"} fontWeight={"bold"} color={Theme.mediumPurple_1}>
          10% de CASHBACK
        </Text>{" "}
        em TODO PEDIDO
      </Text>
    ),
    description: (
      <Text>
        Ganhe <b>10%</b> de cashback em <b>todos</b> os pedidos pagos.
      </Text>
    ),
  },
  {
    image: <Item3Vector />,
    title: (
      <Text>
        <Text as={"span"} fontWeight={"bold"} color={Theme.mediumPurple_1}>
          5x BOOSTs
        </Text>{" "}
        INCLUSOS
      </Text>
    ),
    description: (
      <Text>
        Economize <b>R$ 49,50 todo mês</b> impulsionando 5 pedidos quaisquer.
      </Text>
    ),
  },
  {
    image: <Item5Vector />,
    title: (
      <Text>
        <Text as={"span"} fontWeight={"bold"} color={Theme.mediumPurple_1}>
          8x GURU
        </Text>{" "}
        FAVORITO
      </Text>
    ),
    description: (
      <Text>
        Economize <b>R$ 39,20 todo mês</b> direcionando 8 pedidos para gurus.
      </Text>
    ),
  },
  {
    image: <Item7Vector />,
    title: (
      <Text>
        IDENTIFICAÇÃO{" "}
        <Text as={"span"} fontWeight={"bold"} color={Theme.mediumPurple_1}>
          GURU PRO
        </Text>
      </Text>
    ),
    description: (
      <Text>
        Todos os gurus <b>saberão</b> que você é <b>PRO</b> ao verem seus
        pedidos.
      </Text>
    ),
  },
];
