import {
  AspectRatio,
  Button,
  Link,
  Stack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import Logo from "../../shared/vectors/Logo";

import { Theme } from "../../styles/Theme";
import { LOGIN_LINK, MAIN_PAGE_LINK } from "../faq/types";

export default function HeaderContainer(): JSX.Element {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      maxWidth="1680px"
      paddingX={["16px", "20px", "60px", "120px", "120px"]}
      paddingY={"16px"}
      color={Theme.white}
      position={"absolute"}
      top={"0px"}
      left={"50%"}
      transform="translateX(-50%)"
      zIndex={10}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Link href={MAIN_PAGE_LINK}>
          <AspectRatio
            ratio={164 / 48}
            width={["120px", "120px", "140px", "196px"]}
          >
            <Logo width="100%" height="100%" />
          </AspectRatio>
        </Link>
        <Tag
          variant={"outline"}
          minHeight={"16px"}
          padding={["2px 6px", "2px 8px", "4px 12px", "4px 16px"]}
          fontSize={["8px", "12px", "14px", "16px"]}
          fontWeight={"semibold"}
          color={Theme.white}
          border={`1px solid ${Theme.white}`}
          borderRadius={["4px", "4px", "6px", "8px"]}
          backgroundColor={Theme.darkestPurple_3}
        >
          <TagLabel>PRO</TagLabel>
        </Tag>
      </Stack>
      <Stack
        direction={"row"}
        spacing={["16px", "28px", "32px", "40px"]}
        alignItems={"center"}
        fontSize={["12px", "14px", "16px", "16px"]}
      >
        <Link href={LOGIN_LINK} fontWeight={"medium"}>
          Entrar
        </Link>
        <Link href="#ofertas">
          <Button
            paddingX={"24px"}
            borderRadius={"full"}
            height={"min-content"}
            padding={["8px 12px", "10px 24px", "12px 24px"]}
            fontSize={"inherit"}
            color={Theme.darkestNeutral}
            backgroundColor={Theme.white}
          >
            Assinar
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
}
