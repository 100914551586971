import { Flex, Text } from "@chakra-ui/react";

const Copyright = () => {
  return (
    <Flex
      bg="#F4F4F4"
      padding={["0 16px", "0 16px", "0 48px", "0 48px"]}
      minHeight="47px"
      align="center"
      justify="center"
    >
      <Flex
        direction="row"
        align="center"
        width="100%"
        maxWidth="1240px"
        borderTop="1px solid rgba(207, 207, 210, 0.5)"
      >
        <Text
          color="#9CA3AF"
          fontSize={["10px", "10px", "14px", "14px"]}
          marginTop="10px"
        >
          © 2021-2024 Meu Guru® | 42.269.770/0001-84
        </Text>
      </Flex>
    </Flex>
  );
};

export default Copyright;
