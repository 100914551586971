import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { Theme } from "../../../../styles/Theme";
import { IReview } from "../../types";

interface IProps {
  review: IReview;
}

export default function ReviewCard({ review }: IProps): JSX.Element {
  return (
    <Stack
      spacing={"20px"}
      justifyContent={"space-between"}
      width={"100%"}
      height={["min-content", "min-content", "min-content", "100%"]}
      minHeight={["444px", "444px", "444px", "280px"]}
      padding={"24px"}
      borderRadius={"32px"}
      backgroundColor={Theme.white}
      color={Theme.black}
      fontSize={["20px", "20px", "20px", "22px"]}
      textAlign={"left"}
    >
      {review.description}
      <Stack
        direction={"row"}
        alignSelf={"flex-end"}
        alignItems={"center"}
        fontSize={"17px"}
        color={Theme.darkNeutral}
      >
        <Text>{review.name}</Text>
        <Box>
          <Image src={review.picPath} boxSize={"40px"} alt={"Gabriel Cortez"} />
        </Box>
      </Stack>
    </Stack>
  );
}
